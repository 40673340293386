import React, { useState } from "react";
import { Link } from "react-router-dom";
import { navList } from "../data/Data";
import SocialIcons from "./SocialIcons";

export default function Header() {
  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleMouseEnter = (itemId) => {
    if (window.innerWidth > 991.98) {
      setActiveDropdown(itemId);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 991.98) {
      setActiveDropdown(null);
    }
  };

  const handleDropdownClick = (itemId) => {
    if (window.innerWidth <= 991.98) {
      setActiveDropdown(activeDropdown === itemId ? null : itemId);
    }
  };

  const handleNavLinkClick = (isDropdown) => {
    // If it's a dropdown, don't close the navbar
    if (!isDropdown) {
      setNavbarCollapse(false); // Close the navbar on mobile when a link is clicked
    }
  };

  return (
    <>
      <div className="container-fluid bg-dark px-0">
        <div className="row gx-0">
          <div className="col-lg-3 bg-dark d-none d-lg-block">
            <Link
              to="/"
              className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center"
            >
              <h1 className="m-0 text-primary text-uppercase">
                <img
                  src="../assets/img/logo.png"
                  alt="Logo"
                  width="100"
                  height="100"
                  className="d-inline-block align-top logo-bg"
                  style={{
                    backgroundColor: "#fff", // Apply white background
                    padding: "5px",
                    width: "75px",
                    height: "75px",
                  }}
                />
              </h1>
            </Link>
          </div>
          <div className="col-lg-9">
            <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
              <Link to="/" className="navbar-brand d-block d-lg-none">
                <h1 className="m-0 text-primary text-uppercase">
                  <img
                    src="../assets/img/logo.png"
                    alt="Logo"
                    width="100"
                    height="100"
                    className="d-inline-block align-top"
                    style={{
                      backgroundColor: "#fff", // Apply white square background
                      padding: "5px",
                      width: "75px",
                      height: "75px",
                    }}
                  />
                </h1>
              </Link>
              <button
                type="button"
                className="navbar-toggler"
                onClick={() => setNavbarCollapse(!navbarCollapse)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={
                  navbarCollapse
                    ? "navbar-collapse justify-content-around navbarCollapse"
                    : "collapse navbar-collapse justify-content-around"
                }
              >
                <div className="navbar-nav mr-auto py-0">
                  {navList.map((item, index) => (
                    <div key={index}>
                      {item.subItems ? (
                        <div
                          className="nav-item dropdown"
                          onMouseEnter={() => handleMouseEnter(item.id)}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => handleDropdownClick(item.id)} // Use click for mobile dropdown
                        >
                          <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-toggle="dropdown"
                            onClick={() => handleNavLinkClick(true)} // Pass true to prevent closing navbar on dropdown
                          >
                            {item.text}
                          </Link>
                          <div
                            className={`dropdown-menu rounded-0 m-0 ${
                              activeDropdown === item.id ? "show" : ""
                            }`}
                          >
                            {item.subItems.map((sub, subIndex) => (
                              <Link
                                key={subIndex}
                                to={sub.path}
                                className="dropdown-item"
                                onClick={() => handleNavLinkClick(false)} // Close navbar on non-dropdown click
                              >
                                {sub.text}
                              </Link>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <Link
                          to={item.path}
                          className="nav-item nav-link"
                          onClick={() => handleNavLinkClick(false)} // Close navbar on normal links
                        >
                          {item.text}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
                <SocialIcons />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
